@import "./assets/mapbox-gl.css";
/* @import ' hls.js/dist/hls.css'; */

/* colour palette */

:root {
    --main-accent-colour: #BD3437;
    --light-red: #ef325a;
    --pink: #f0325a;
    --dark-red: #be0029;
    --black: #000;
    --white: #fff;
    --dark-gray: #b5b2b2;
    --light-gray: #f9f8f8;
}

body {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

/* THIS IS TO STYLE PLACE HOLDER TEXT */

::placeholder {
    color: var(--light-red);
}

.place-actions {
    text-align: center;
}

.place-actions a {
    color: var(--light-red);
    width: 50%;
    display: inline-block;
    text-align: center;
}

.place-actions a:active {
    color: var(--light-gray);
}


/*font-face */


/* @font-face {
    font-family: "helvetica-light-font";
    src: url("../font/Helvetica-Light.ttf");
}


@font-face {
    font-family: "helvetica-regular-font";
    src: url("../font/Helvetica.ttf");
}


@font-face {
    font-family: "helvetica-heavy-font";
    src: url("../font/Helvetica-Bold.ttf");
}

@font-face {
    font-family: "arial-font";
    src: url("../font/Arial.ttf");
} */

.bi-invalid-input {
    background-color: #fffacd !important;
}

.bi-invisible {
    display: none;
}

.bi-ctn-notify {
    /*background-color:#ffe1cd;*/
    padding: 0 .5em;
    margin-bottom: .5em;
    /*border: 1px solid #e9cfbd;*/
    /*border-radius:3px 3px;*/
}

#splash-logo {
    display: block;
    margin: 50% auto auto;
}

.ui-li-static.ui-collapsible>.ui-collapsible-heading {
    margin: 0;
}

.ui-li-static.ui-collapsible {
    padding: 0;
}

.ui-li-static.ui-collapsible>.ui-collapsible-heading>.ui-btn {
    border-top-width: 0;
}

.ui-li-static.ui-collapsible>.ui-collapsible-heading.ui-collapsible-heading-collapsed>.ui-btn,
.ui-li-static.ui-collapsible>.ui-collapsible-content {
    border-bottom-width: 0;
}

.ui-listview>li p {
    white-space: normal;
}

.small-font-size {
    font-size: 16px;
}

.medium-font-size {
    font-size: 20px;
}

.large-font-size {
    font-size: 24px;
}

.list-no-left-padding {
    padding-left: 0;
}


/* #map_canvas {
    position: relative;
} */

div[data-role="page"] {
    overflow: hidden;
    height: 100%;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

.background-logo {
    /*width: 165%;*/
    height: auto;
    /*position: absolute;*/
    position: fixed;
    /*left: -222px;*/
    /*top: -50px;*/
    top: -60px;
    z-index: -1;
    /*background-color: #000;*/
    /*padding-bottom: 1000px;*/
    width: 100%;
}

.page-header {
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    margin-top: 27px;
    margin-bottom: 14px;
}

.ios .page-header {
    font-family: -apple-system-font, "Helvetica Neue", HelveticaNeue, "Roboto", "Segoe UI", sans-serif;
}

.large-green-input-label {
    /*height: 130px;*/
    height: 123px;
    display: inline-block !important;
    /*width: 131px;*/
    width: 123px;
    background-color: #7DAA41;
    border-radius: 12px;
    /* font-family: helvetica-light-font, arial-font, sans-serif; */
    font-size: 20px !important;
    color: #fff;
    font-weight: 100 !important;
    text-align: center;
    /*line-height: 214px;*/
    /*line-height: 210px;*/
    /*line-height: 244px;*/
}

.large-white-input {
    /* padding: 12px; */
    /*width: 176px;*/
    /* width: calc(100% - 164px); */
    /*max-width: 300px;*/
    /* max-width: 400px; */
    display: inline-block !important;
    /* font-family: arial-font, sans-serif; */
    font-size: 17px;
    /*margin-left: 12px;*/
    border-radius: 12px;
    /* border: 0; */
    /* height: 16px; */
    vertical-align: top;
    /* margin-top: 40px; */
    /*margin: 45px 12px;*/
    /* margin: 45px 8px; */
    margin: 34px 8px;
    margin-left: 19px;
    width: calc(100% - 168px);
    color: var(--light-red);
    border: 2px solid var(--light-red);
}

.large-green-input-label {
    background-repeat: no-repeat;
    background-position-x: center;
}

.large-green-input-label-text {
    margin-top: 92px;
    display: block;
}

.ui-content {
    /*padding:12px 11px 10px 9px;*/
    /*padding:12px 26px 10px 26px;*/
    /*padding:12px 26px 10px 26px;*/
    /* padding:12px 26px 74px 26px; */
    padding: 12px 19px 10px 19px;
    /*margin-bottom: 73px;*/
    margin-bottom: 80px;
}

.ui-icon-arrow-right {
    float: right;
    position: relative;
    top: 14px;
    right: 10px;
    height: 30.895px;
    width: 18.132px;
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.132' height='30.895' viewBox='0 0 43 74' shape-rendering='geometricPrecision' text-rendering='geometricPrecision' image-rendering='optimizeQuality' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath fill='%23f0325a' fill-rule='nonzero' d='M0 5l4-5 39 37L4 74l-4-5 34-32z'/%3E%3C/svg%3E");
}

.current {
    display: block !important;
}

.extra-top-margin {
    /*margin-top: 15px;*/
    margin-top: 14px;
}

.small-margin-paragraph {
    /*margin: 5px auto;*/
    margin: 6px auto;
}

.indent-paragraph {
    padding-left: 8px;
}

.copyright-container {
    font-size: 13pt;
    visibility: hidden;
}

#login-button-logo {
    position: absolute;
    top: -105px;
    left: 50%;
    margin-left: -49px;
    z-index: 2;
}

.big-black-header {
    background: #000;
    height: 80px;
    margin-top: -27px;
    padding-top: 27px;
    /*margin-bottom: 33px;*/
}

.big-black-home-header,
.big-black-settings-header {
    margin-top: -27px;
    padding-top: 27px;
    background: #000;
}

.wide-white-input {
    box-sizing: content-box;
    font-family: inherit;
    background: var(--white);
    /* font-family: arial-font, sans-serif; */
    font-size: 15px;
    border-radius: 12px !important;
    /* border: 0; */
    /*padding: 8px 8px 7px;*/
    padding: 12px !important;
    /*width: 308px;*/
    /* width: calc(100% - 24px); */
    width: calc(100% - 28px);
    max-width: 400px;
    /* margin-bottom: 7px!important; */
    margin-bottom: 8px !important;
    color: var(--light-red);
    border: 2px solid var(--light-red);
}

.wide-white-input a {
    color: var(--light-red);
}

a.no-bold-link {
    font-weight: regular;
}


/* a.wide-white-input:active, button.wide-white-input:active, .picture-button:active, .wide-white-input:active  {
    background: #e8e8e8;
} */

.submit-button:active {
    /* background: #6f9838; */
    background: var(--dark-gray);
}

.cancel-button:active {
    border-color: var(--dark-red);
    color: var(--dark-red);
    /* background: #963c5a!important; */
}

#registration-capture {
    /*padding-left: 17px;*/
    /*padding-right: 14px;*/
}

#registration-capture .submit-button {
    /* padding: 12px; */
}


/*#reg-submit-button, #reg-login-button {
    padding: 5px 2px 7px;
    color: #fff;
    font-size: 18px;
    background: #7DAA41;
    width: 224px;
    margin: 14px auto;
    border-radius: 9px;
    border: 0;
    font-weight: 100;
    text-align: center;
    font-family: helvetica-light-font, arial-font, sans-serif;
}*/

#reg-login-button {
    /*position: fixed;*/
    /*bottom: 79px;*/
    /*left: 50%;*/
    /*transform: translate(-50%, 0);*/
    /* margin-top: 50px; */
    margin-top: 165px;
}

#ctn-notify,
#ctn-notify-error {
    display: none;
    top: 80px;
    font-size: 17px;
    background: var(--light-gray);
    border: 2px solid var(--light-red);
    padding: 1px 27px 1px 26px;
}

#ctn-notify-error {
    color: var(--dark-red);
}

.error-label {
    color: var(--dark-red);
}

.wide-white-input-highlight,
.large-white-input-highlight,
.large-white-input-highlight-error,
.wide-white-input-highlight-error {
    border-color: #7DAA41 !important;
    border-width: 4px !important;
    border-style: solid !important;
    /*padding: 4px 8px 3px;*/
    padding: 8px 8px 9px !important;
}

.pseudo-select-box.large-white-input-highlight,
.pseudo-select-box.large-white-input-highlight-error,
.pseudo-select-box.wide-white-input-highlight-error {
    width: calc(100% - 8px);
}

.large-white-input-highlight-error,
.wide-white-input-highlight-error {
    border-color: #A94264 !important;
}

#login.ui-header {
    background-color: transparent;
    border: 0;
}

.single-item-body {
    line-height: 29px;
}

#register-success .ui-content {
    padding-top: 120px;
}

#register-success .single-item-body {
    margin-top: 26px;
}

.button-list li
/*, #file-list li*/

    {
    /* height: 75px; */
    height: 62px;
    /* border-bottom: 2px solid rgba(255,255,255,0.5); */
    border-bottom: 1px solid var(--black);
    /*padding-left: 67px;*/
    /* line-height: 75px; */
    line-height: 62px;
    position: relative;
}

.button-list li.last
/*, #file-list li.last*/

    {
    border: 0;
}

.button-list a {
    /* color: #fff; */
    color: var(--black);
    /*font-size: 18px;*/
    /* font-size: 16.75px; */
    font-size: 18px;
    /*font-family: helvetica-light-font Neue, Helevetica, arial-font, sans-serif;*/
    /* font-family: helvetica-regular-font, arial-font, sans-serif; */
    /* font-weight: 300; */
    font-weight: normal;
    /* padding-left: 67px; */
    padding: 2px 0 0 67px;
    height: 60px;
    border: 0;
    /*padding-top: 0!important;*/
    /* padding-top: 7px; */
    background-repeat: no-repeat;
    background-position-x: 15px;
    background-position-y: 17px;
    background-position-y: 10px;
    display: block;
}

.icon-background {
    /* background-color: #2A2A2A; */
    border-radius: 50%;
    width: 42px;
    position: absolute;
    display: block;
    height: 42px;
    top: 17px;
    left: 15px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

#intelligence-report-icon {
    /* background-image: url(../img/report_icon_green.svg); */
}

#incident-report-icon {
    /* background-image: url(../img/incident_report_icon_green.svg); */
}

#alerts-icon {
    /* background-image: url(../img/alerts_icon_green.svg); */
    background-position-y: 8px;
}

#settings-icon {
    /* background-image: url(../img/settings_icon_green.svg); */
    /* background-position-y: 5px; */
}

#logout-icon {
    /* background-image: url(../img/logout_icon_green.svg); */
    background-position-y: 6px;
}

.green-bar {
    background-color: #7DAA41;
    /* font-family: helvetica-light-font, arial-font, sans-serif; */
    font-size: 16.75px;
    font-weight: 100;
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
    height: 35px;
    padding-left: 25px;
    line-height: 35px;
}

#intelligence-report .ui-content,
#incident-report .ui-content {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.tagline {
    font-size: 13px;
}

#category-tagline {
    /* padding-left: 25px; */
    padding-right: 25px;
    /* margin-bottom: 12px; */
    margin-bottom: 0px;
}

#incident-report #category-tagline {
    margin: 0;
}

#intelligence-report .indent-paragraph,
#incident-report .indent-paragraph {
    padding-left: 25px;
}

#intelligence-report .extra-top-margin,
#incident-report .extra-top-margin {
    margin-top: 70px;
}

#bad-media-icon {
    /* background-image: url(../img/bad_media_icon_green.svg); */
    background-position-y: 11px;
}

#health-safety-icon {
    /* background-image: url(../img/health_safety_icon_green.svg); */
}

#labour-actions-icon {
    /* background-image: url(../img/labour_action_icon_green.svg); */
    background-position-y: 7px;
}

#legislation-changes-icon {
    /* background-image: url(../img/legislation_icon_green.svg); */
}

#security-crime-icon {
    background-position-y: 7px;
    /* background-image: url(../img/security_crime_icon_green.svg); */
}

#service-disruption-icon {
    /* background-image: url(../img/service_disruption_icon_green.svg); */
}

#social-instability-icon {
    /* background-image: url(../img/social_instability_icon_green.svg); */
}

#terror-icon {
    /* background-image: url(../img/terror_icon_green.svg); */
}

#transport-traffic-disruption-icon {
    /* background-image: url(../img/transport_traffic_disruption_icon_green.svg); */
}

.subtopic-header {
    /* font-size: inherit; */
    font-size: 18px;
    font-weight: inherit;
    /* color: #7CAA41; */
    padding-left: 26px;
    /*margin-bottom: 18px;*/
    color: var(--dark-red);
}

.subtopic-header .icon-background {
    position: static;
    display: inline-block;
    /* background-color: #434344; */
    vertical-align: -14px;
    margin-right: 8px;
    background-size: 42px;
}


/* #chosen-category {
    display: none;
} */

#report-form-subcontainer {
    /* padding-left: 27px; */
    /* padding-right: 27px; */
}

#incident-report #report-form-subcontainer {
    padding-bottom: 16px;
}

#subtopic-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 5px;
}

#subtopic-list li {
    /*width: 313px;*/
    width: calc(100% - 20px);
    max-width: 400px;
    background-color: #fff;
    color: #2A2A2A;
    /*padding: 8px 10px 5px;*/
    /*padding: 0px 10px 0px;*/
    padding: 8px 10px 8px;
    border-radius: 10px;
    margin-bottom: 6px;
    border: 0;
}

#subtopic-list li input {
    display: none;
}

#subtopic-list li label,
#select-incident-list li label {
    display: inline-block;
    /*margin: 0;*/
    /*margin: 0 0 5px 12px;*/
    margin: 0 0 0 12px;
}

#select-incident-list li label {
    margin: 0;
    width: calc(100% - 48px);
    padding-right: 12px;
}

#subtopic-list li .pseudo-radio,
#select-incident-list .pseudo-radio {
    display: inline-block;
    width: 13px;
    height: 13px;
    border: 1px solid #B3B5B7;
    background: #fff;
    border-radius: 50%;
    vertical-align: -1px;
}

#select-incident-list .pseudo-radio {
    /*vertical-align: text-bottom;*/
    position: absolute;
    top: 19px;
    right: 17px;
}

#subtopic-list li .pseudo-radio.selected,
#select-incident-list .pseudo-radio.selected {
    background-color: #7CAA41;
}

#subtopic-container {
    margin-bottom: 8px;
}


/*#textarea-information-label {
    color: #7CAA41;
    font-size: 15px;
    margin-bottom: 3px;
}*/

#textarea-information {
    border: none;
    border-radius: 12px;
    /*height: 45px;*/
    /*width: 313px;*/
    padding: 8px 10px 5px;
    /* font-family: helvetica-light-font, arial-font, sans-serif; */
    font-size: 15px;
    color: #2b2b2b;
}

#incident-location-header,
#information-location-header {
    font-size: 13px;
    /* font-weight: 100; */
    font-weight: normal;
    /* margin-top: 15px; */
    margin-top: 11px;
    margin-bottom: 0px;
    color: var(--dark-red);
}

#incident-location-tagline,
#information-location-tagline,
.under-label {
    font-size: 10.3px;
    margin-top: 1px;
    margin-bottom: 11px;
    line-height: 15px;
}

.medium-green-label {
    color: #7CAA41;
    font-size: 15px;
    margin-bottom: 3px !important;
}

.small-white-label {
    /* font-size: 13px; */
    font-size: 15px;
    margin-bottom: 4px !important;
    color: var(--dark-red);
}

.small-black-label {
    color: var(--black);
    font-size: 15px;
    margin-bottom: 4px !important;
}

.location-input {
    /*width: 313px;*/
    /*padding-bottom: 6px;*/
    padding: 12px 12px 6px;
    height: 18px;
    margin-bottom: 13px;
}

.location-input.last-location-input {
    margin-bottom: 11px;
}

#datetime-datetime {
    /*padding: 4px 8px 5px;*/
    padding: 12px 12px 6px;
    /*border-radius: 10px;*/
    /*width: 313px;*/
    margin-bottom: 2px;
    height: 18px;
}

.background-container {
    background-color: rgba(255, 255, 255, 0.45);
}

#incident-submit {
    margin-bottom: 0;
}

#intelligence-report .extra-top-margin,
#incident-report .extra-top-margin {
    margin-top: 0;
    padding-top: 35px;
    padding-bottom: 11px;
    /*background-color: rgba(109,109,109,0.94);*/
    background-color: transparent;
}

#intelligence-report .copyright-container,
#incident-report .copyright-container {
    margin-bottom: 0;
}

#intelligence-report .background-logo,
#incident-report.background-logo {
    /*border: 500px solid #000;*/
    /*left: -722px;*/
    /*top: -560px;*/
}

.green-text {
    color: #7DAA41;
}

#submit-incident .black-page-background {
    padding-bottom: 18px;
    padding-top: 12px;
}

.pseudo-select-box {
    margin-top: 7px;
    color: #2a2a2a;
    /*text-align: center;*/
    text-align: left;
    position: relative;
    font-size: 18px;
    height: 18px;
    /*padding: 8px 12px 7px!important;*/
    padding: 8px 0 7px !important;
    width: 100%;
    z-index: 1;
}

.pseudo-select-box-click {
    /*background-color: #2a2a2a;*/
    /*border-radius: 50%;*/
    /*width: 21px;*/
    /*height: 21px;*/
    width: 22px;
    height: 22px;
    /*background-image: url("../img/select_down_arrow_green.svg");*/
    /* background-image: url("../img/choose_incident_type_drop_down_arrow.svg"); */
    /* background-image: url("assets/images/choose_incident_type_drop_down_arrow.svg"); */
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 6px;
}

.pseudo-select-box-selected {
    display: inline-block;
    padding-left: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 60px);
    white-space: nowrap;
}

.pseudo-select-box ul {
    list-style-type: none;
    padding-left: 0;
}

.pseudo-select-box ul li {}

.pseudo-select-box .pseudo-select-options-list {
    color: #fff;
    border-radius: 12px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    text-align: initial;
}

#select-incident-list {
    display: none;
}

.select-option-group-title {
    border-bottom: 1px solid #fff;
    display: block;
    padding: 16px 0 15px 12px;
}

.select-option-group-option {
    /*padding-left: 12px;*/
    padding: 16px 0 15px 12px;
    border-bottom: 1px solid #fff;
    position: relative;
}

.last-select-option-group-option {
    border: 0;
}

#select-incident-list input {
    display: none;
}

#button-choose-location {
    width: 100%;
    /* font-size: 18px; */
    font-size: 13px;
    padding: 8px !important;
    margin-bottom: 12px;
}

.loading-container {
    /*position: absolute;*/
    position: fixed !important;
    min-width: 100% !important;
    /*min-height: 100%;*/
    /*display: -webkit-box;*/
    /*display: -webkit-flex;*/
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    z-index: 50 !important;
    /* display: none; */
}

.loading {
    height: 30% !important;
    color: #fff !important;
    width: 50% !important;
    min-height: 197px !important;
    /* background-image: url(assets/images/loader.svg); */
    background-color: rgba(0, 0, 0, 0.5) !important;
    background-repeat: no-repeat !important;
    background-position-x: center !important;
    background-position-y: 20% !important;
    display: none !important;
    /*position: absolute;*/
    z-index: 100 !important;
    border-radius: 12px !important;
    text-align: center !important;
    /*left: calc(50% - 25%);*/
    /*top: calc(50% - 25%);*/
    min-width: 191px !important;
    max-width: 300px !important;
    max-height: 310px !important;
    font-weight: 700 !important;
    font-size: 1.7em !important;
    /* font-family: helvetica-heavy-font, arial-font, sans-serif; */
    /*position: fixed;*/
    position: relative !important;
}

.loading-text {
    position: absolute !important;
    bottom: 0 !important;
    text-align: center !important;
    width: 100% !important;
    left: 0 !important;
    padding-bottom: 1.5em !important;
}

#start {
    background-color: #fff;
    /* background-image: url("../img/TopicWorx_Logo-animation_new_fixed.gif"); */
    background-position: center;
    background-repeat: no-repeat;
    display: none;
}

#chat-page-messages {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 12px;
    padding: 10px;
    overflow-y: scroll;
}

.chat-message {
    margin-bottom: 10px;
}


/* ABSA SPECIFIC - START */

body {
    /*font-size: 130%;*/
    /*line-height: 110%;*/
    /* font-family: helvetica-light-font, arial-font, sans-serif; */
    /*color:#fff;*/
    /* font-size: 16.75px; */
    /* font-weight: 100; */
    margin: 0;
    height: 100%;
    text-shadow: none;
    /* font-size: 16px; */
    /* font-size: 16pt; */
    font-size: 17px;
    font-family: Roboto;
    color: var(--black);
}

body.ios {
    font-family: -apple-system-font, "Helvetica Neue", HelveticaNeue, "Roboto", "Segoe UI", sans-serif;
}

.large-input-container {
    /*height: 130px;*/
    height: 123px;
    width: 100%;
    border-radius: 12px;
    /*background-color: rgba(255,255,255,0.2);*/
    /* background-color: #A9ABAE; */
    margin: 9px auto;
}

.large-red-input-label {
    /* height: 123px; */
    /* display:inline-block!important; */
    /* width: 123px; */
    /* background-color: var(--main-accent-colour); */
    /* border-radius: 12px; */
    /* font-family: helvetica-light-font, arial-font, sans-serif; */
    /* font-size: 20px!important; */
    /* color: #fff; */
    /* font-weight: 100!important; */
    /* text-align: center; */
}

.large-red-input-label {
    background-repeat: no-repeat;
    background-position-x: center;
    display: inline-block !important;
    height: 112px;
    width: 112px;
}

.large-red-input-label-text {
    margin-top: 92px;
    display: block;
}

a {
    text-decoration: none;
    /*color: #7DAA41;*/
    /* color: var(--main-accent-colour); */
    /* font-size: 16px; */
    /* font-weight: 700; */
    /* font-family: helvetica-heavy-font, arial-font, sans-serif; */
    font-weight: bold;
    color: var(--dark-red);
}

a.solid-light-red-button {
    min-width: 225px;
    text-align: center;
    display: block;
    background: var(--light-red);
    color: var(--white);
    font-size: 18pt;
    margin: auto;
    height: 30px;
    border-radius: 12px;
    padding: 6px;
    font-weight: normal;
}

button.solid-light-red-button {
    min-width: 225px;
    text-align: center;
    display: block;
    background: var(--light-red);
    color: var(--white);
    font-size: 18pt;
    margin: auto;
    border-radius: 12px;
    padding: 6px;
    font-weight: normal;
    border: 0;
    height: 42px;
}

button.solid-light-red-button:active {
    background-color: var(--dark-red);
}

:focus {
    outline: none;
}

input:focus {
    background: var(--light-gray);
}

:hover {}

.more-indent-paragraph {
    padding-left: 17px;
}

.big-red-header {
    background: var(--main-accent-colour);
    height: 80px;
    margin-top: -27px;
    padding-top: 27px;
    /*margin-bottom: 33px;*/
}

.header-logo {
    position: absolute;
    /*width: 12%;*/
    /*width: 45px;*/
    width: 65px;
    height: auto;
    left: 16px;
    top: 8px;
}

.button-list
/*, #file-list */

    {
    /*background: rgba(255,255,255,0.25);*/
    /* background: rgba(198,52,55,0.4); */
    list-style-type: none;
    /* border-radius: 12px; */
    padding-left: 0;
}

#intelligence-report .big-black-header,
#incident-report .big-black-header,
#intelligence-report .big-red-header,
#incident-report .big-red-header {
    margin-bottom: 0;
}

.wide-input {
    /* font-family: arial-font, sans-serif; */
    font-size: 15px;
    border-radius: 12px !important;
    border: 0;
    /*padding: 8px 8px 7px;*/
    padding: 12px !important;
    /*width: 308px;*/
    width: calc(100% - 24px);
    max-width: 400px;
    margin-bottom: 7px !important;
}

.big-red-button,
.big-light-red-button {
    color: #fff;
    background-color: var(--main-accent-colour);
    font-size: 20px;
    border-radius: 12px;
    border: 0;
    padding: 12px !important;
    width: 79%;
    max-width: 400px;
    margin: 4.75px auto;
    display: block;
}

.big-light-red-button {
    background-color: var(--light-red);
}


/* #national-alerts-icon {
    background-image: url(../img/national_alerts_icon_red.svg);
} */

.page-header-block {
    height: 81px;
    border: 1px solid var(--white);
}

.secondary-header .icon-background {
    /* top:14px; */
    top: 0;
    /* left: 27px; */
    left: 0;
    background-color: #FFF;
    border-radius: 0;
    width: 43px;
    height: 43px;
}

.secondary-header {
    font-size: 18px;
    font-weight: bold;
    /* width: 100%; */
    margin-top: 0px;
    position: relative;
    padding-left: 78px;
    margin-top: 4px;
}

#secondary-settings-header {
    font-weight: bold;
    padding-top: 11px;
    padding-left: 51px;
}

.red-text {
    color: var(--main-accent-colour);
}

.gray-border {
    border: 3px solid #A9ABAE;
}

.wide-white-input.gray-border {
    /* width: calc(100% - 30px); */
}

.form-label {
    /* font-family: helvetica-light-font, arial-font, sans-serif; */
    /* color:#000; */
    /* font-size: 16.75px!important; */
    font-size: 17px !important;
    /* font-weight: 100!important; */
    margin-bottom: 5px !important;
    /* margin-bottom: 4px!important; */
    height: 17px;
}

.submit-button,
.cancel-button {
    display: block;
    padding: 5px 2px 7px;
    /* padding: 8px 2px 4px; */
    color: #fff;
    font-size: 18px;
    background: var(--light-red);
    width: 220px;
    margin: 14px auto;
    border-radius: 9px;
    border: 0;
    /* font-weight: 100; */
    text-align: center;
    /* font-family: helvetica-light-font, arial-font, sans-serif; */
    font-family: inherit;
    height: 23px;
    font-weight: normal;
    box-sizing: content-box;
}

.content-header {
    /* color: var(--main-accent-colour); */
    color: var(--light-red);
    /* font-weight: 700; */
    font-weight: normal;
    font-size: 22px;
    margin-bottom: 21px;
    /* font-family: helvetica-heavy-font, arial-font, sans-serif; */
}

#settings-icon {
    /* background-image: url(../img/settings_icon_red.svg); */
    /* background-position-y: 5px; */
}

.wide-gray-input {
    background: #A9ABAE;
    color: #FFF;
    /* font-family: helvetica-light-font, arial-font, sans-serif; */
    font-size: 18px;
    border-radius: 12px !important;
    border: 0;
    /*padding: 12px!important;*/
    padding: 9px !important;
    width: calc(100% - 24px);
    max-width: 400px;
    margin-bottom: 14px !important;
    /*height: 15px;*/
    height: 21px;
    line-height: 21px;
}

#registration-disclaimer {
    margin-top: 1px;
}

.square-checkbox-label {
    display: inline-block !important;
    vertical-align: top;
    margin-left: 8px !important;
    font-size: 13px;
    width: calc(100% - 45px);
    /*margin-top: -3px;*/
}

.square-checkbox-label a {
    font-weight: normal;
}

.inactive-button,
this.formData.inactive-button.solid-light-red-button {
    pointer-events: none;
    background-color: #b5b2b2;
}

button[disabled],
button:disabled {
    pointer-events: none;
    background-color: #b5b2b2;
}

#incident-report .ui-content {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

#incident-report .indent-paragraph {
    padding-left: 25px;
}

#incident-report .extra-top-margin {
    margin-top: 70px;
}

#incident-report .extra-top-margin {
    margin-top: 0;
    padding-top: 35px;
    padding-bottom: 11px;
    /*background-color: rgba(109,109,109,0.94);*/
    background-color: transparent;
}

#incident-report .copyright-container {
    margin-bottom: 0;
}

.secondary-header-no-icon {
    padding-left: 16px;
}

.medium-red-label {
    color: var(--main-accent-colour);
    font-size: 15px;
    margin-bottom: 3px !important;
}

.pseudo-select-box-click {
    /*background-color: #2a2a2a;*/
    /*border-radius: 50%;*/
    /*width: 21px;*/
    /*height: 21px;*/
    width: 22px;
    height: 22px;
    /*background-image: url("../img/select_down_arrow_green.svg");*/
    background-image: url("assets/images/choose_incident_type_drop_down_arrow_red_white.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 6px;
}

.cancel-button {
    border: 2px solid var(--light-red);
    color: var(--light-red);
    background: var(--white);
    width: 218px;
    /* padding: 2px 2px 4px; */
    height: 19px;
}


/* ABSA SPECIFIC - END */


/* MAKE THINGS LOOK OKAY ON IOS - START */

.ios #selected-incident-type {
    position: absolute;
    top: 4px;
}

.ios #select-incident-list {
    margin-top: 30px;
}

.ios #datetime-datetime {
    display: block;
    margin: 6px auto 30px !important;
}

.ios .button-list a {
    height: 70px;
    padding-top: 0 !important;
}

.chat-name-me {
    font-weight: 700;
}

.chat-message-time {
    color: #7DAA41;
    font-size: 80%;
}

#chat-page-message-send {
    margin-top: 10px;
}

#chat-subscription-only {
    display: none;
}

#file-list .pseudo-checkbox {
    /*margin: 28px 0px 28px 28px;*/
    /*background: #fff;*/
}

#file-list {
    padding-left: 0;
}

#file-list li {
    list-style-type: none;
}

.file-list-button {
    height: 24px;
    border-radius: 12px;
    border: none;
    background: #7DAA41;
    font-size: 15px;
    width: 129px;
    /*padding: 1px 6px 2px;*/
    padding: 2px 6px;
    /*line-height: 0;*/
    display: inline-block;
    color: #fff;
    /* font-family: helvetica-light-font, arial-font, sans-serif; */
    font-weight: 100;
    text-align: center;
}

#btn-upload {
    display: block;
    padding: 5px 2px 7px;
    font-size: 18px;
    /*background: #7DAA41;*/
    width: 224px;
    margin: 14px auto;
    border-radius: 9px;
}

#file-buttons-secondary {
    display: none;
    text-align: center;
}

#btn-delete {
    background: #A94264 !important;
}


/* MAKE THINGS LOOK OKAY ON IOS - END */


/*#start-gif {
    width: 100%;
    max-width: 440px;
    margin:0 auto;
}*/


/*[data-role=page]{height: 100% !important; position:relative !important;}*/


/*[data-role=footer]{bottom:0; position:absolute !important; top: auto !important; width:100%;}*/


/* some overrides */


/*.ui-header {
    border: 0;
}

#login .ui-input-text {
    border: 0;

}*/


/* jquery mobile icons */

#aura-details-popup {
    /*position: absolute;*/
    /*position: fixed;*/
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    /*min-height: 100%;*/
    /*display: -webkit-box;*/
    /*display: -webkit-flex;*/
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    display: none;
}

#aura-details-form {
    /*height: 80%;*/
    color: #fff;
    width: 80%;
    min-height: 360px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 20%;
    /*display: none;*/
    z-index: 100;
    border-radius: 12px;
    text-align: center;
    min-width: 191px;
    max-width: 300px;
    max-height: 310px;
    position: relative;
    /*padding-bottm: 50px;*/
}

#aura-details-form input {
    background: #fff;
    /* font-family: arial-font, sans-serif; */
    border-radius: 12px !important;
    border: 0;
    width: 45%;
    padding: 3px 8px;
    margin-bottom: 7px !important;
}

#aura-details-form label {
    display: inline-block;
    margin-right: 5px;
    text-align: right;
    width: 45%;
}

#aura-shake-monitor {
    display: none;
}

.ui-icon-plus:after {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%237DAA41%22%20points%3D%2214%2C5%209%2C5%209%2C0%205%2C0%205%2C5%200%2C5%200%2C9%205%2C9%205%2C14%209%2C14%209%2C9%2014%2C9%20%22%2F%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.ui-alt-icon.ui-icon-plus:after,
.ui-alt-icon .ui-icon-plus:after {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20points%3D%2214%2C5%209%2C5%209%2C0%205%2C0%205%2C5%200%2C5%200%2C9%205%2C9%205%2C14%209%2C14%209%2C9%2014%2C9%20%22%2F%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.ui-icon-minus:after {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Crect%20y%3D%225%22%20style%3D%22fill%3A%237DAA41%3B%22%20width%3D%2214%22%20height%3D%224%22%2F%3E%3C%2Fsvg%3E");
}

.ui-alt-icon.ui-icon-minus:after,
.ui-alt-icon .ui-icon-minus:after {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Crect%20y%3D%225%22%20width%3D%2214%22%20height%3D%224%22%2F%3E%3C%2Fsvg%3E");
}

.ui-icon-chat {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42.615' height='42.615' viewBox='266.597 387.294 42.615 42.615'%3E%3Cpath fill='%232A2B2A' d='M309.212 408.601c0 11.768-9.539 21.309-21.309 21.309-11.766-.002-21.307-9.541-21.307-21.309s9.541-21.307 21.307-21.307c11.77 0 21.309 9.539 21.309 21.307'/%3E%3Cpath fill='%237BA840' d='M290.456 414.82c-.368 0-.731-.032-1.089-.095l-.284-.034-.673-.101a13.472 13.472 0 0 1-6.822-3.051l-.317-.303a10.101 10.101 0 0 1-3.259-5.334 8.037 8.037 0 0 1-.202-1.151c-3.367 1.272-5.59 3.96-5.59 6.896-.014 2.162 1.219 4.27 3.367 5.731a.668.668 0 0 1 .296.559v4.404l3.368-3.091a.676.676 0 0 1 .58-.169c.754.139 1.521.209 2.289.209 3.872 0 7.406-1.798 9.011-4.471h-.675v.001z'/%3E%3Cpath fill='%237BA840' d='M291.359 394.507c-6.741 0-12.217 4.257-12.217 9.489s5.488 9.489 12.229 9.489a15.696 15.696 0 0 0 2.815-.256h.122a.67.67 0 0 1 .457.176l4.365 3.992v-5.718a.672.672 0 0 1 .295-.559c2.613-1.805 4.162-4.404 4.162-7.125.002-5.231-5.486-9.488-12.228-9.488z'/%3E%3C/svg%3E");
}


/* ABSA ICONS */


/*******************************************/

.ui-alt-icon.ui-icon-plus:after,
.ui-alt-icon .ui-icon-plus:after {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20points%3D%2214%2C5%209%2C5%209%2C0%205%2C0%205%2C5%200%2C5%200%2C9%205%2C9%205%2C14%209%2C14%209%2C9%2014%2C9%20%22%2F%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3Cg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.ui-alt-icon.ui-icon-minus:after,
.ui-alt-icon .ui-icon-minus:after {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Crect%20y%3D%225%22%20width%3D%2214%22%20height%3D%224%22%2F%3E%3C%2Fsvg%3E");
}

.ui-icon-incident-report {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42.614' height='42.614' viewBox='0 0 42.614 42.614'%3E%3Cpath fill='%23E6E7E8' d='M42.614 21.308c0 11.768-9.54 21.307-21.308 21.307C9.539 42.614 0 33.075 0 21.308 0 9.54 9.539 0 21.306 0c11.768 0 21.308 9.54 21.308 21.308'/%3E%3Cpath fill='%23BD3437' d='M30.527 7.502c.473.32.628.82.709 1.336.133.853.238 1.712.332 2.57.061.55.092 1.103.111 1.655.045 1.303.092 2.606.109 3.91.03 2.268.047 4.537.055 6.807.002.748-.027 1.495-.053 2.243-.031.882-.043 1.767-.115 2.644-.103 1.256-.155 2.521-.492 3.746-.066.238-.168.475-.29.691-.179.316-.503.332-.822.314-.737-.035-1.153-.541-1.524-1.084-.557-.814-1.076-1.655-1.637-2.467-.988-1.434-2.22-2.604-3.766-3.437-.955-.515-1.967-.873-3.016-1.095-.79-.166-1.609-.203-2.414-.291-.352-.037-.706-.055-1.08-.084v-9.021c.386-.026.774-.04 1.16-.084.678-.076 1.359-.152 2.034-.261 1.178-.191 2.285-.602 3.334-1.168.748-.403 1.428-.904 2.055-1.476 1.238-1.127 2.129-2.515 3.008-3.917.306-.487.623-.977 1.117-1.307.131-.087.277-.151.416-.226h.769zM7.046 18.831c.202-.46.35-.955.618-1.373.555-.861 1.381-1.354 2.401-1.481.431-.054.871-.056 1.306-.058 1.274-.007 2.547-.003 3.821-.003h.309v9.074h-.282c-1.512 0-3.024.006-4.534-.002-1.343-.006-2.416-.547-3.13-1.693-.23-.369-.343-.811-.509-1.219v-3.245zM9.855 26.034h5.687c.084.744.135 1.466.255 2.175.148.864.311 1.731.747 2.51.075.131.186.246.297.351.665.625 1.01 1.383.924 2.299-.094.998-.645 1.709-1.76 1.736-.722.018-1.446 0-2.17.006-.664.004-1.24-.186-1.69-.701-.299-.344-.449-.75-.533-1.184-.384-1.968-.781-3.933-1.345-5.859-.118-.402-.269-.795-.401-1.193-.016-.052-.009-.109-.011-.14M32.92 23.056V17.86c.941.336 1.654 1.329 1.732 2.326.109 1.376-.693 2.456-1.732 2.87'/%3E%3C/svg%3E");
}

.ui-icon-intelligence-report {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42.615' height='42.614' viewBox='0 0 42.615 42.614'%3E%3Cpath fill='%23E6E7E8' d='M42.615 21.307c0 11.768-9.54 21.307-21.308 21.307C9.54 42.614 0 33.075 0 21.307S9.54 0 21.307 0c11.768 0 21.308 9.539 21.308 21.307'/%3E%3Cpath fill='%23BD3437' d='M11.114 20.994c0-3.61-.001-7.219 0-10.828.001-1.506.654-2.158 2.161-2.158 3.764-.001 7.528.005 11.292-.008.399-.002.684.107.969.394 1.908 1.933 3.84 3.842 5.744 5.78.189.194.334.529.334.8.02 5.685.018 11.372.012 17.056 0 1.319-.703 2.018-2.006 2.019-5.491.002-10.983.002-16.475 0-1.336-.001-2.029-.697-2.031-2.054-.003-3.667 0-7.334 0-11.001M23.239 9.828h-.66c-2.444 0-4.889-.001-7.334 0-1.583.002-2.294.724-2.294 2.329-.001 5.919-.001 11.838 0 17.756 0 1.599.715 2.315 2.307 2.315 4.075.002 8.149.002 12.226 0 1.594 0 2.307-.717 2.309-2.313V16.343h-.678c-1.359 0-2.719.01-4.076-.004-.84-.009-1.522-.527-1.717-1.309-.076-.296-.078-.615-.08-.924-.007-1.394-.003-2.788-.003-4.278'/%3E%3Cpath fill='%23BD3437' d='M21.4 24.771c1.804 0 3.608-.004 5.414.002.649.002.977.248 1.063.759.085.501-.19.937-.69 1.045-.225.048-.461.05-.692.051-3.415.003-6.829.002-10.244.001-.961 0-1.394-.286-1.407-.923-.013-.644.416-.935 1.377-.935 1.725-.001 3.452 0 5.179 0M21.351 30.344h-5.413c-.537 0-.958-.164-1.068-.76-.098-.527.202-.969.732-1.062.189-.034.386-.034.58-.034 3.453-.002 6.907-.002 10.36 0 .193 0 .391 0 .58.029.523.085.779.432.768.935-.013.479-.279.815-.779.863-.441.042-.889.028-1.336.028-1.475.003-2.949.001-4.424.001M21.347 21.055c1.804 0 3.607-.001 5.413.001.752.001 1.153.331 1.14.925-.014.573-.402.899-1.119.899-3.609.006-7.218.006-10.827 0-.711 0-1.104-.334-1.111-.908-.009-.592.392-.916 1.15-.917 1.783-.001 3.569 0 5.354 0M18.109 17.317c.755 0 1.51-.01 2.265.003.627.012.964.34.969.905.005.566-.324.919-.954.926-1.529.019-3.058.019-4.587-.001-.603-.007-.971-.403-.959-.939.012-.536.387-.882 1.001-.892.755-.01 1.51-.002 2.265-.002'/%3E%3C/svg%3E");
}

.ui-icon-chat {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42.615' height='42.615' viewBox='266.597 387.294 42.615 42.615'%3E%3Cpath fill='%23E6E7E8' d='M309.212 408.601c0 11.768-9.539 21.309-21.309 21.309-11.766-.002-21.307-9.541-21.307-21.309s9.541-21.307 21.307-21.307c11.77 0 21.309 9.539 21.309 21.307'/%3E%3Cpath fill='%23BD3437' d='M290.456 414.82c-.368 0-.731-.032-1.089-.095l-.284-.034-.673-.101a13.472 13.472 0 0 1-6.822-3.051l-.317-.303a10.101 10.101 0 0 1-3.259-5.334 8.037 8.037 0 0 1-.202-1.151c-3.367 1.272-5.59 3.96-5.59 6.896-.014 2.162 1.219 4.27 3.367 5.731a.668.668 0 0 1 .296.559v4.404l3.368-3.091a.676.676 0 0 1 .58-.169c.754.139 1.521.209 2.289.209 3.872 0 7.406-1.798 9.011-4.471h-.675v.001z'/%3E%3Cpath fill='%23BD3437' d='M291.359 394.507c-6.741 0-12.217 4.257-12.217 9.489s5.488 9.489 12.229 9.489a15.696 15.696 0 0 0 2.815-.256h.122a.67.67 0 0 1 .457.176l4.365 3.992v-5.718a.672.672 0 0 1 .295-.559c2.613-1.805 4.162-4.404 4.162-7.125.002-5.231-5.486-9.488-12.228-9.488z'/%3E%3C/svg%3E");
}


/*atttempt at styling toasts */

.hijack-hotspot-item {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmVyc2lvbj0iMS4wIiB2aWV3Qm94PSIwIDAgMjA2MDUwIDIwNjA1MCIgc2hhcGUtcmVuZGVyaW5nPSJnZW9tZXRyaWNQcmVjaXNpb24iIHRleHQtcmVuZGVyaW5nPSJnZW9tZXRyaWNQcmVjaXNpb24iIGltYWdlLXJlbmRlcmluZz0ib3B0aW1pemVRdWFsaXR5IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTEwOTQ2NCA1NDA3OXY2NDM4OEg5NjU4NlY1NDA3OWgxMjg3OHpNMTAzMDI1IDBjMjg0NDUgMCA1NDIxMSAxMTUzNiA3Mjg0OSAzMDE3OCAxODY0NCAxODY0MCAzMDE3NiA0NDQwNiAzMDE3NiA3Mjg1MSAwIDI4NDQxLTExNTM2IDU0MjAzLTMwMTc4IDcyODQzLTE4NjQwIDE4NjM4LTQ0NDAyIDMwMTc4LTcyODQ3IDMwMTc4cy01NDIwMy0xMTUzMi03Mjg0Ny0zMDE3NkMxMTU0MCAxNTcyMzYgMCAxMzE0NzQgMCAxMDMwMjljMC0yODQ1MyAxMTUzMi01NDIxMSAzMDE3Ni03Mjg1M0M0ODgxOCAxMTUzMiA3NDU4MCAwIDEwMzAyNSAwem02Mzc0NiAzOTI4M2MtMTYzMTEtMTYzMTQtMzg4NTMtMjY0MDQtNjM3NDYtMjY0MDRTNTU1OTAgMjI5NjkgMzkyNzkgMzkyNzljLTE2MzEwIDE2MzExLTI2NDAwIDM4ODQ5LTI2NDAwIDYzNzUwIDAgMjQ4OTMgMTAwOTQgNDc0MzEgMjY0MDQgNjM3NDIgMTYzMTEgMTYzMTAgMzg4NDkgMjY0MDAgNjM3NDIgMjY0MDBzNDc0MzEtMTAwOTQgNjM3NDItMjY0MDRjMTYzMDYtMTYzMDcgMjY0MDQtMzg4NDkgMjY0MDQtNjM3MzggMC0yNDg5Ny0xMDA5NC00NzQzOS0yNjQwMC02Mzc0NnptLTYzNzQ2IDkyMDYzYzU2ODggMCAxMDI5OCA0NjEwIDEwMjk4IDEwMzE2IDAgNTY3Ny00NjEwIDEwMzAyLTEwMjk4IDEwMzAyLTU2OTIgMC0xMDMwMi00NjI1LTEwMzAyLTEwMzAyIDAtNTcwNiA0NjEwLTEwMzE2IDEwMzAyLTEwMzE2eiIgZmlsbD0iI2JlMDAyOCIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");
    background-size: 48px 48px;
    background-repeat: no-repeat;
    background-position: left;
    height: 48px;
    padding-left: 60px;
}

.hotspot-warning.toast-warning {
    background: #fff;
    color: #000;
    /* padding: 0.1em 1em; */
    padding: 10px;
    margin-top: 0;
    border-radius: 5px;
    margin-bottom: 10px;
}

.toast-success.absa-toast {
    background: #fff;
    /* padding: 0 .5em; */
    margin-bottom: .5em;
    top: 80px;
    font-size: 17px;
    background: var(--light-gray);
    border: 2px solid var(--light-red);
    color: var(--dark-red);
    /* padding: 1px 27px 1px 26px;*/
    padding: 26px !important;
}

.toast-error.absa-toast {
    background: #fff;
    /* padding: 0 .5em; */
    margin-bottom: .5em;
    top: 80px;
    font-size: 17px;
    background: var(--light-gray);
    border: 2px solid var(--light-red);
    padding: 1px 27px 1px 26px;
    color: var(--dark-red);
    /* padding: 1px 27px 1px 26px;*/
    padding: 26px !important;
}

.toast-info.absa-toast {
    background: #fff;
    /* padding: 0 .5em; */
    margin-bottom: .5em;
    top: 80px;
    font-size: 17px;
    background: var(--light-gray);
    border: 2px solid var(--light-red);
    padding: 1px 27px 1px 26px;
    color: var(--dark-red);
    /* padding: 1px 27px 1px 26px;*/
    padding: 26px !important;
}

.hijack-hotspot-item h3 {
    /* margin-bottom: 0.3em; */
    margin-bottom: 6px;
    margin-top: 6px;
    font-size: 19px;
    font-weight: normal;
    line-height: 19px;
}

.hijack-hotspot-item p {
    /* margin-top: 0.3em; */
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 16px;
}

@media print {
    body {
        display: none;
    }
}